<template>
FAQ

What is a NFT?
A non-fungible token (NFT) is a cryptocurrency token that is indivisible and unique. One NFT cannot be interchanged with another NFT, and the whole cannot be broken down into smaller parts and used. NFTs offer myriad options for creating and trading digital assets — such as original artwork and blockchain-integrated collectible game items. They act as a non-duplicable digital certificate of ownership for any assigned digital asset.

NFTs are useful for proving the scarcity and provenance of rare assets, both digital and real-world. Once the code is written, it is then minted, or permanently published, into a token. Non-fungible tokens are mainly built on Ethereum using the ERC-721 token standard, which OLTA extends.

__________________________________________________________________________
What wallet can I use?
Currently Metamask, it works on your computer, iOS, and Android phones.
Download and install the MetaMask browser extension through their website.
Select “Create a Wallet.”
Create a username and password - IMPORTANT that you write down the seed phrase connected to your wallet and store it in a place you won't forget. If you forget your password in the future there is no option to recover your password.
Metamask will prompt you to confirm the phrase before finalising your account set up.
Success! Your Metamask wallet is now set up and ready to connect.
Click here for instructions on how to create an account

__________________________________________________________________________
How can I buy an artwork on OLTA?
You’ll need a MetaMask wallet and ERC-20 cryptocurrency in your Metamask wallet to buy artworks on OLTA.

If you do not have any ERC-20 tokens in your Metamask wallet you will need to swap cryptocurrencies on exchanges such as Uniswap, Coinbase, Binance or in your Metamask wallet itself.

You will need to have the following ERC-20 tokens in your Metamask wallet to buy artwork on OLTA.
WETH - Wrapped Ethereum
WBTC - Wrapped Bitcoin
DAI - Stablecoin
USDT - Stablecoin
USDC - Stablecoin
LINK - ChainLink
BNB  - Binance

Buy Now
You can buy an artwork by clicking on the ‘Buy Now’ button and paying the amount the artist has set.

Make an Offer
You can make a offer on an artwork by clicking on the ‘Make a offer’ button, then
select any of the ERC-20 tokens from the drop down list (Which you have in your metamask wallet)
Set any amount you would like to offer
Set your ‘Sell On Share’. (add link)
You will need to click on the ‘Approve’ button as the final step.
Your offer then exists on the market until the owner accepts or you remove your offer.

Remove an Offer
You can remove your offer on an artwork by clicking on the ‘Remove Offer’ button.

__________________________________________________________________________
What are ERC20 tokens?

The popular cryptocurrency and blockchain system known as Ethereum is based on the use of tokens, which can be bought, sold, or traded.

One of the most significant Ethereum tokens is known as ERC-20. ERC-20 has emerged as the technical standard; it is used for all smart contracts on the Ethereum blockchain for token implementation and provides a list of rules that all Ethereum-based tokens must follow.

__________________________________________________________________________



What is Wrapped ETH (WETH) and why does OLTA use it?
A good place to start is what is ETH? Ether or ETH is the native currency built on the ethereum blockchain. ETH does not comply with ERC20 token standards and cannot be traded directly with other ERC20 tokens. ERC20 token standards were developed after the release of ETH.

Wrapped ETH, or wETH is traded at a 1:1 ratio of ETH. The reason you need wETH is to be able to trade ETH for other ERC-20 tokens on decentralized platforms.
How to wrap your ETH?
You are able to wrap your ETH via your metamask wallet. You can simply do this via the "swap" function. Or you can use Uniswap to swap Eth to Weth or any other erc-20 token.

Get some ERC-20 tokens on Uniswap: https://app.uniswap.org/#/swap

__________________________________________________________________________

What do you get when you buy an NFT?
You get the ownership of the interactive artwork, which comprises a web page with custom code and art created by an interactive artist or team.


__________________________________________________________________________
Ownership
Owning an NFT on OLTA grants the users wallet the right to accept bids and prove they own that piece of media. Owning a piece of media captures the value as you as a collector have seen value in that piece. As the owner of this piece, you are able to either hold on to it or resell it to whomever you wish.

Exploring the concept of Cryptomedia
Crytomedia captures the paradox that while an artistic work is allowed to be used freely, it is still valuable and people will still pay the original creator for the right to be the owner of the work. It is akin to a painting being owned, but displayed in a public gallery for free. The public enjoys it, but the creator/future owner can still buy and sell the ownership rights of the work. The concept of Crytpomedia is described fully at https://cryptomedia.wtf/.


__________________________________________________________________________

What is the Owner Share?
Your share when you sell on.

When an owner sells a NFT, a percentage of that sale goes to the owner before them. This fee is designed to reflect and reward the role of owners’ reputation in creating price appreciation. The owner reward is set when making a bid, allowing for negotiation at time of sale.

__________________________________________________________________________
What is the Creator Share?
Every time an interactive NFT is created on OLTA, the artist sets a creator reward: a permanent, fixed percent of sale value that is sent to you on every sale. The percentage chosen at time of minting is the final percentage and cannot be changed after minting.

__________________________________________________________________________
What is ‘Sell on Share’?
When a nfy is sold, a percentage can go back to the previous owner or artist.
Give example - popular person

The Sell On Share is set by the Buyer of the NFT to give the current owner a share of the profit, when they sell the NFT..

When you sell the artwork on, the previous owner will receive this share of equity.



Resale Fee
If you re-sell this piece, the person you're buying it from now will earn this percentage as a reward for selling it to you.


__________________________________________________________________________

How do I become an Artist?
Do you make art with web technologies? Especially new media, code art, interactive, digital art, webXR? Reach out to us via DM on Twitter. We will launch an open call for artists or teams to produce interactive art in the coming months. Right now, we’re busy preparing 5 initial interactive artists' works for public launch from July 2021.

Message us on Twitter or email terence@olta.art if you’d like to chat about being an artist?



__________________________________________________________________________

What features exist NOW on OLTA platfom?

Load / Save Feature
We have developed a Load / Save feature which enables you to save changes you make to interactive artworks on OLTA using a GUI menu named ‘Options’. When you click Save, your change is stored on a centralised server, which you can Load down from the server at a later time. When Art collectors click save, their saves are automatically saved to their user account on OLTA.

Zora Protocol
We have integrated Zora, a trusted established protocol to handle the buying, selling and bidding of all interactive NFT’s on OLTA.

zora.co

__________________________________________________________________________

What is Zora protocol?

Zora protocol is fully open source. It is a protocol, not a marketplace: open, permissionless, and permanent.

Zora’s approach is to invert the standard model of goods and marketplaces. Instead of building a marketplace around tokens and selling tokens you own, they built the marketplace into the token protocol instead. The tokens themselves can accept bids and offers. We all know that whoever owns the marketplace owns the commission. But when you mint a token using Zora, because the marketplace is literally in the token you own, you are the owner of that marketplace. If you sell your work to someone who sells it to someone else, you make a commission on that future sale as well. We call this fee Perpetual Equity, and you can set it each time you mint a work.

Moreover, baking the marketplace into the protocol itself means your token is re-sellable anywhere, via any platform, curator, or market. can publish to any platform or market. Buying and selling is direct and unmediated, and value accrues to the work itself, not the marketplace.

Read more: Zora Blog

__________________________________________________________________________



Buying / Selling / Bidding?
As founders and artists, we believe in Zora’s vision which is to give ownership and control to the creators | owners of digital art.

Zora is a universal media registry protocol. It’s a way for creators to publish creative media, earn money on their work, and have others build and share what they create. Because this media is based on a protocol, all this happens without the lock-in of social media platforms.

Read what they do: https://zora.co/manifesto
Learn how we they do it: https://zora.engineering/whitepaper


__________________________________________________________________________

What is webXR?
WebXR is the latest evolution in the exploration of virtual and augmented realities; it taps the power of the web along with the unification of these realities, under one umbrella, making it easier to create immersive 3D art, interactive environments, VR tools and more. The XR stands for “Extended Reality” which is intended to include AR, VR as well as 3D web.

We see the potential for cultural artistic ideas to be expressed and explored with web technologies. WebXR Interactive content such as 3D web, Web AR and WebVR is rendered on a web browser. With wide access to a web browser, this new medium can change how we think and see the world around us.


__________________________________________________________________________

What is generative art?
Generative art refers to art that in whole or in part has been created with the use of an autonomous system. An autonomous system in this context is generally one that is non-human and can independently determine features of an artwork that would otherwise require decisions made directly by the artist.

__________________________________________________________________________


What are Saves? | Where are my saves stored?

You have the opportunity to save your changes to the Interactive artworks on OLTA using the ‘Options panel’ in the menu bar. When you click Save, your change is stored on a centralised server. The artwork that you altered is also saved to your user account which you can store for later.

The idea of saves is to allow you to take control of art, to personalise and / or leave your mark!

__________________________________________________________________________

Does OLTA have any fees?

10% Commission Fees
We ONLY take commission fees when artists / owners use our Auction house feature.
Our fee for auctions is 10% of sale. Auction house comprises a timed auction, with an option to set a reserve base price as well as being exclusively featured by OLTA’s homepage and all social media channels.

No Commision Fees
If an artist / owner chooses to publish, sell or buy artworks on OLTA without Auction house, Olta do not take any commission fees. We have made this choice in line with Zora protocol which is to 100% support creativity and ownership of content.

Artist Fees
The only cost that is required to post your media is the gas fee at time of posting. When you pay gas to submit a transaction, you are paying for the computational energy needed to power the validation of that transaction on Ethereum.


__________________________________________________________________________

What is Zora Auction House?

We are currently integrating Zora Auction house into OLTA !

In a metaverse of millions of NFTs, the act of curation is crucial. We will Integrate Zora Auction House into OLTA. We will play the role of curator, auctioning NFTs on behalf of creators and collectors. Creators and collectors on OLTA will be able to list their interactive NFTs for timed reserve auctions for primary and secondary sales.

With Auction House, OLTA will:
↳ Approve/deny proposals for an NFT to be listed
↳ Earn a curator fee
↳ Cancel an auction prior to commencing

 The Auction House has a feature set:
+ Run auctions in ETH or any ERC20 token
+ Run auctions on any ERC721 (SDK only)
+ Honor creator resale-royalties
+ Usable for primary and secondary auctions


__________________________________________________________________________


Who are the founders?
We’re two artists who can code. We have 26 years collective professional experience in 3D interactive design, web Development, AR / VR art and animation, generative art, robotics, 3D design, character rigging, shader design and 3D Animation.

CEO | Co Founder
Terence is a character animator, digital artist and interactive artist who has worked in the entertainment industry for over 20 years for clients such as Google, Disney, Aardman, Sony and BBC.

Portfolio
Linked-In
Twitter

CTO | Co - Founder
George is a creative technologist through and through. Web developer with a background in the arts. Prior to that he was building robots that draw. Awarded multiple artists residences & exhibiting in the UK, Germany and the USA.

Portfolio
Twitter



__________________________________________________________________________

This sounds great, how can I help?

Donate
You can help by donating any amount to help us fund development on new platform features and tools to benefit artists, collectors and audiences.

Paypal Donate Button


__________________________________________________________________________



Sign Up
Sign Up for more info regarding our founder tokens which we aim to release from July 2021.
Owners of founder tokens receive an ERC-721 interactive NFT artwork as well as exclusive access, airdrops…

SignUp Button
Here’s our mailing list on mailChimp - http://eepurl.com/gwEDeD


__________________________________________________________________________


Where can I go for additional support?
Need anything else? Get in touch with us, join our Discord community, or message us on Twitter,  Instagram or Cent. You can also email us at support@olta.art. (set up a new one)


__________________________________________________________________________
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: "Docs",
})
</script>
